import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Відгуки | CraftStories - шкіряні витвори мистецтва
			</title>
			<meta name={"description"} content={"Опис Історії елегантності, сплетені разом нашими заповітними покровителями"} />
			<meta property={"og:title"} content={"Відгуки | CraftStories - шкіряні витвори мистецтва"} />
			<meta property={"og:description"} content={"Опис Історії елегантності, сплетені разом нашими заповітними покровителями"} />
			<meta property={"og:image"} content={"https://estriter.live/img/00.jpg"} />
			<link rel={"shortcut icon"} href={"https://estriter.live/img/6000699.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://estriter.live/img/6000699.png"} />
			<meta name={"msapplication-TileImage"} content={"https://estriter.live/img/6000699.png"} />
			<meta name={"msapplication-TileColor"} content={"https://estriter.live/img/6000699.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Відгуки
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="center"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					У CraftStories ми пишаємося тим, що створюємо не просто шкіряні вироби, а досвід. Пориньте в історії тих, хто вирішив подорожувати з нами, і нехай їхні слова надихнуть вас на створення власної шкіряної історії.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"Нещодавно придбав ремінь для годинника на замовлення, і результат перевершив мої очікування. Увага до деталей, якість шкіри та майстерність були просто неперевершеними. Щоразу, коли я його ношу, я відчуваю себе особливим"
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=200&q=80"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1554651802-57f1d69a4944?auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Мохайло
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"З того моменту, як я почала робити замовлення, і до дня, коли я його отримала, все було бездоганно. Рідко можна зустріти таку відданість своїй справі та задоволенню потреб клієнтів. Мій новий гаманець - витвір мистецтва".
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=200&q=80"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1619950466709-02c2bf682442?auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Анна
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"Я завжди був прискіпливим до рукавичок, але пара, яку я отримав від CraftStories, підійшла мені ідеально. Якість неперевершена, і вони швидко стали основним елементом мого зимового гардеробу".
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=200&q=80"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=500&q=80 500w,https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=800&q=80 800w,https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=1080&q=80 1080w,https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=1600&q=80 1600w,https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=2000&q=80 2000w,https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=2600&q=80 2600w,https://images.unsplash.com/photo-1602480370486-ddc38af362cb?auto=format&fit=crop&w=3200&q=80 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Петро
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});